.share {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    &__group {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    &__url_btn {
        width: 48px;
        height: 48px;

        font-weight: bolder;
        font-size: 1rem;

        color: var(--article-text-primary);
        background: var(--share-btn-bg);

        border-radius: 24px;
        border: 0px;
        
        cursor: pointer;

        &:hover {
            background: var(--share-btn-hover);
        }
    }
}