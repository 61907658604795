@import 'app/styles/breakpoints';
@import 'app/styles/color';
@import 'app/styles/font';

.header {
    color: var(--text-primary);
    background: var(--header-bg);

    display: flex;
    justify-content: center;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    
    &__container {
        width: 1280px;
        padding: 1rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }

    &__title__wrapper {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 2rem;
        display: flex;
        align-items: center
    }

    &__wrapper {
        flex-grow: 1;
        display: flex;

        @media (max-width: $md) {
            display: none;
        }
    }

    &__title {
        font-size: 1.5rem;
        font-family: $nasalization;
        cursor: pointer;
    }

    /** Navigation menu. */

    &__navigation-menu {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 10;
    }

    &__navigation-menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__navigation-menu-list {
        display: flex;
        padding: 0;
        gap: 1rem;
    }

    &__navigation-menu-item {
        display: flex;
    }

    &__navigation-menu-link,
    &__menu_link {
        color: var(--text-primary);
        font-size: 1rem;

        cursor: pointer;
        text-decoration: none;
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 0% 101%;
        background-repeat: no-repeat;
        background-size: 0% 2px;
        transition: background-size 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);

        &:hover {
            color: var(--header-link-hover);
            background-size: 100% 2px;
            transition: background-size 0.3s ease-in-out;
        }

        &_active {
            color: var(--header-link-active) !important;
            background-size: 100% 2px;
        }
    }

    &__login {
        fill: var(--header-icon);
        width: 1.75rem;
        height: 1.75rem;
    }

    &__link,
    &__menu_link {
        display: flex;
        align-items: center;
    }

    &__link {
        @media (max-width: $md) {
            display: none;
        }
    }

    &__menu_link {
        display: none;

        @media (max-width: $md) {
            display: inline;
            width: fit-content;
        }
    }

    &__menu_wrapper {
        margin-top: 2rem;
        display: flex;
        gap: 0.5rem;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        
        @media (min-width: $md) {
            display: none;
        }
    }

    &__menu_login {
        font-size: 1rem;
    }

    &__menu_img {
        width: 1.3rem;
        border-radius: 100%;
    }

    &__user_img {
        width: 2rem;
        border-radius: 100%;
    }

    &__icon_menu {
        display: none;
        fill: var(--header-icon);
        width: 1.75rem;
        height: 1.75rem;

        @media (max-width: $md) {
            display: inline;
        }
    }

    &__icon_close {
        display: none;
        fill: var(--header-icon);
        width: 1.75rem;
        height: 1.75rem;

        grid-column: 2 / 3;
        grid-row: 1 / 2;

        @media (max-width: $md) {
            display: inline;
        }
    }

    &__conatiner_open {
        @media (max-width: $md) {
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto;
            gap: initial;

            .header__wrapper {
                display: flex;

                grid-column: 1 / 2;
                grid-row: 2 / 3;

                margin-top: 2rem;

                & .header__navigation-menu-list {
                    width: 100%;
                    flex-direction: column;
                }
            }
        }
    }
}