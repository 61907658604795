@import "/src/app/styles/font.scss";
@import "/src/app/styles/color.scss";
@import "/src/app/styles/breakpoints.scss";

.article_detail {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    font-family: $pretendard;
    padding-top: 3rem;

    .wrapper__content {
        min-height: 85vh;
        max-width: 48rem;
        margin: 0 auto;
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__category {
            display: inline-block;
            margin-top: 1rem;
            color: var(--article-text-category);
            font-size: 1.3rem;
        }

        &__title {
            margin-top: 0.5rem;
            color: var(--article-text-primary);
            font-size: 3rem;
        }

        &__info {
            margin-top: 0.5rem;
            display: flex;
            color: var(--article-text-info);
        }

        &__time::before {
            display: inline-block;
            background-color: var(--article-text-info);
            // border-radius: 50%;
            content: "";
            height: 2px;
            line-height: 1.43;
            margin: 4px 10px;
            width: 6px;
        }
    }

    &__img {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 0.5rem;

        margin-top: 2rem;
        max-height: 25rem;

        // @media (max-width: $md) {
        //     max-height: 12rem;
        // }
    }

    .content {
        margin-top: 3rem;
        margin-bottom: 6rem;
        color: var(--article-text-primary);
    }

    &__author_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 6rem;
    }

    &__share_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__share_text {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        color: var(--article-text-primary);
    }

    &__prevnnext_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 2rem;
        margin: 4rem 0;
    }

    &__prev_article,
    &__next_article {
        width: 100%;
        font-size: 1rem;
        font-family: $pretendard;
        font-weight: 500;
        padding: 0.7rem 1rem;

        border: 0.15rem solid var(--profile-edit-btn-bg);
        border-radius: 0.6rem;

        background: var(--article-prevnext-btn-bg);
        color: var(--article-prevnext-text-secondary);

        cursor: pointer;

        &:hover {
            background-color: var(--article-prevnext-btn-hover);
            border: 0.15rem solid var(--article-prevnext-btn-hover);
        }

        display: flex;
        align-items: flex-start;
    }

    &__prev_article::before {
        content: "<";
        font-size: 1.5rem;
        margin-right: 1rem;
    }

    &__next_article::after {
        content: ">";
        font-size: 1.5rem;
        margin-left: 1rem;
    }

    &__prev_wrapper,
    &__next_wrapper {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__prev_title,
    &__next_title {
        font-size: 1.3rem;
        color: var(--article-prevnext-text-primary);
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        margin-top: 0.5rem;
        text-align: left;
    }

    .write_btn__wrapper {
        width: 100%;
        max-width: 1345px;
        display: flex;
        justify-content: flex-end;

        position: sticky;
        bottom: 1rem;
        padding-right: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: -4rem;

        pointer-events: none;

        @media (max-width: $md) {
            padding-right: 0rem;
        }
    }

    .write_btn {
        width: 5rem;
        height: 5rem;

        fill: var(--blog-write-btn);
        background: var(--blog-write-btn-bg);

        padding: 1rem;
        border-radius: 100%;
        box-shadow: 0px 0px 1rem 0px rgb(28, 28, 28);

        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background: var(--blog-write-btn-bg-hover);
        }

        z-index: 100;
        pointer-events: all;

        @media (max-width: $md) {
            width: 4rem;
            height: 4rem;
        }
    }
}

.giscus {
    margin-top: 2rem;
}
