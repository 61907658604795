@use 'src/app/styles/breakpoints';

.layout {
    background: var(--white);

    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    &__content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
    }

    &__article {
        width: 100%;
        max-width: 1280px;;
        flex-grow: 1;
        padding: 0 1.5rem;
    }

    &__footer {
        flex: 0 0 auto;
        display: flex;
        justify-content: center
    }

    &__toggle-theme {
        position: fixed;
        bottom: 30px;
        right: 40px;

        width: 45px;
        height: 45px;

        border-radius: 50%;
        box-shadow: 0 0 0 1px var(--border);
        transition: 0.2s linear all;

        z-index: 10;
    }

    &__icon {
        top: 50%;
        transform: translate(0, -50%);
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &__toggle-theme.light {
        background-color: var(--primary-bg);
    }

    &__toggle-theme.dark {
        background-color: var(--black);
    }

    &__toast-container {
        top: 5rem;
    }
}