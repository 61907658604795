@import "app/styles/font.scss";
@import "app/styles/color.scss";
@import "app/styles/breakpoints.scss";

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: calc(100vh - 12rem);

    &__form {
        margin: 4rem 0;
        max-width: 35rem;
        max-height: 35rem;
        width: 100%;
        height: 100%;
        padding: 5.2rem 5rem;

        // border: 0.2rem solid var(--border-accordion);
        border-radius: 5.5rem;
        box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
        background-color: var(--login-bg);

        @media (max-width: $md) {
            grid-template-columns: repeat(2, 1fr);
            padding: 3.2rem 3rem;
            border-radius: 3.5rem;
        }
    }

    &__title {
        height: auto;
        margin-bottom: 1.3rem;

        text-align: justify;
        font-size: 3.3rem;
        font-weight: 500;
        font-family: $pretendard;

        color: var(--text-primary);

        @media (max-width: $md) {
            font-size: 2.3rem;
        }
    }

    &__subtitle {
        color: var(--text-secondary);
        font-family: $pretendard;
        font-size: 1.3rem;

        @media (max-width: $md) {
            font-size: 1.05rem;
        }
    }

    &__title-wrapper {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 1rem;
    }

    &__input-wrapper {
        width: 100%;

        display: flex;
        flex-direction: column;

        gap: 1rem;
    }

    &__input,
    &__btn {
        font-size: 1.2rem;
        font-family: $pretendard;
        padding: 1rem 1rem;
        border-radius: 0.6rem;
        width: 100%;
    }

    &__input {
        border: 0.15rem solid var(--login-input-border);

        &:focus {
            border: 0.15rem solid $indigo-A200;
        }

        &:focus:invalid {
            border: 0.15rem solid var(--login-invalid);
            background-color: var(--login-invalid-bg);
        }
    }

    &__btn {
        font-weight: 500;
        background-color: var(--login-btn);
        border: 0.15rem solid var(--login-btn);

        color: var(--text-primary);
        cursor: pointer;

        &:hover {
            background-color: var(--login-btn-hover);
            border: 0.15rem solid var(--login-btn-hover);
        }
    }
}