@import 'themes/light.scss';
@import 'themes/dark.scss';
@import 'breakpoints.scss';
@import 'font.scss';
@import 'color.scss';

body {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $indigo-600;
    }

    &::-webkit-scrollbar-track {
        background-color: $grey-700;
    }
    
    font-family: $pretendard;
}

.app {
    height: 100%;
    background: var(--white);
    display: flex;
    min-height: 100vh;
    flex-direction: column
}