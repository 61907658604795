@font-face {
    font-family: "Nasalization";
    font-weight: normal;
    src: url("/shared/assets/fonts/nasalization-rg.otf") format('opentype');
  }

@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap');
$gothic-a1: "Gothic A1", sans-serif;

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css");

$pretendard: "Pretendard Variable", sans-serif;
$nasalization: "Nasalization", sans-serif;


@import url('https://fonts.googleapis.com/css2?family=Jersey+20+Charted&display=swap');

$jersey-20-charted: "Jersey 20 Charted", sans-serif;
