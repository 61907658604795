@import "/src/app/styles/font.scss";
@import "/src/app/styles/color.scss";
@import "/src/app/styles/breakpoints.scss";

.editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    font-family: $pretendard;
    color: var(--article-text-primary);
    padding: 2rem 0;

    &__header {
        margin: 0.5rem 0;
        display: flex;
        justify-content: space-between;
    }

    &__article_id {
        font-family: $pretendard;
        color: var(--text-primary);
        display: inline;
    }

    &__backup_wrapper {
        display: flex;
        gap: 0.25rem;
    }

    &__backup_upload,
    &__backup_download {
        fill: var(--text-primary);
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__input {
        width: 100%;
        background: var(--article-editor-bg);
        padding: 1rem;

        text-align: justify;
        font-weight: 500;
        font-family: $pretendard;

        color: var(--text-primary);
        box-shadow: 0 0 0 1px var(--article-shadow-color), 0 0 0 var(--article-shadow-color),
            0 1px 1px var(--article-shadow-color);
    }

    &__title {
        font-size: 3rem;
        margin-bottom: 1rem;

        @media (max-width: $md) {
            font-size: 1.5rem;
        }
    }

    &__cid,
    &__category,
    &__thumbnail {
        flex-grow: 1;
        font-size: 1.5rem;
    }

    &__subinfo_wrapper {
        width: 100%;
        display: flex;
        gap: 1rem;
        margin: 1rem 0;

        @media (max-width: $lg) {
            flex-wrap: wrap;
        }
    }

    &__thumbnail_wrapper {
        width: 100%;
        padding: initial;
        background: var(--article-editor-bg);
        color: var(--article-text-secondary);
    }

    &__thumbnail_preview_active {
        background: var(--article-editor-bg-active);
    }

    &__thumbnail_image {
        max-height: 13rem;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        max-width: 100%;
    }

    &__thumbnail_label {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    &__thumbnail_logo {
        fill: var(--article-text-secondary);
        width: 6rem;
    }

    &__thumbnail_active {
        display: block;
    }

    &__thumbnail_inactive {
        display: none;
    }

    &__thumbnail_generator {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        margin-top: 1rem;
    }

    &__thumbnail_generator_wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0.6rem;
    }

    &__thumbnail_generator_input {
        margin-top: 0.25rem;
    }

    &__thumbnail_colorpicker_wrapper {
        display: flex;
        gap: 1rem;
    }

    &__thumbnail_generate {
        width: 100%;
        background: var(--article-thumbnail-generate-btn);
        border: 0.15rem solid var(--article-thumbnail-generate-btn);
        margin-top: 1rem;

        &:hover {
            background: var(--article-thumbnail-generate-btn-hover);
            border: 0.15rem solid var(--article-thumbnail-generate-btn-hover);
        }
    }

    &__thumbnail_apply {
        width: 100%;
        background: var(--article-thumbnail-apply-btn);
        border: 0.15rem solid var(--article-thumbnail-apply-btn);

        &:hover {
            background: var(--article-thumbnail-apply-btn-hover);
            border: 0.15rem solid var(--article-thumbnail-apply-btn-hover);
        }
    }

    &__submit_wrapper {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        gap: 1rem;
    }

    &__btn {
        font-size: 1.2rem;
        font-family: $pretendard;
        font-weight: 500;
        flex-grow: 1;
        padding: 1rem 2rem;
        border-radius: 0.6rem;

        color: var(--text-primary);
        cursor: pointer;
    }

    &__submit {
        width: 0;
        background-color: var(--article-submit-btn);
        border: 0.15rem solid var(--article-submit-btn);

        &:hover {
            background-color: var(--article-submit-btn-hover);
            border: 0.15rem solid var(--article-submit-btn-hover);
        }
    }

    &__edit {
        width: 0;
        background-color: var(--article-edit-btn);
        border: 0.15rem solid var(--article-edit-btn);

        &:hover {
            background-color: var(--article-edit-btn-hover);
            border: 0.15rem solid var(--article-edit-btn-hover);
        }
    }

    &__delete {
        width: 0;
        background-color: var(--article-delete-btn);
        border: 0.15rem solid var(--article-delete-btn);

        &:hover {
            background-color: var(--article-delete-btn-hover);
            border: 0.15rem solid var(--article-delete-btn-hover);
        }
    }

    details {
        width: 100%;
        margin-top: 1rem;
        background: var(--article-editor-bg);
        padding: 1rem;
    }

    summary {
        font-size: 1.5rem;
    }
}

#thumbnail_canvas {
    border: 0.15rem dashed var(--text-primary);
    max-width: 768px;
    max-height: 400px;
    width: 100%;
}

#thumbnail_title_size {
    padding: initial;
    box-shadow: none;
}

.react-colorful {
    width: 100% !important;
    max-width: 200px;
}
