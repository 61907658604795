@import "app/styles/font.scss";
@import "app/styles/color.scss";
@import "app/styles/breakpoints.scss";

.author {
    &__wrapper {
        width: 100%;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        color: var(--article-text-primary);


        @media (max-width: $md) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }

    &_user_wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &__img {
            display: block;
            object-fit: cover;
            border-radius: 100%;

            height: 100%;
            max-height: 10rem;

            margin-right: 2rem;


            @media (max-width: $md) {
                // max-height: 6rem;
                margin-right: 0rem;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        &__name {
            font-size: 2.4rem;
            font-weight: bolder;
        }

        &__bio {
            max-height: 10rem;
            width: 14rem;
            margin-top: 0.6rem;

            font-size: 1rem;
            font-family: $pretendard;

            color: var(--article-text-primary);
            background: transparent;

            border: none;
            resize: none;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $indigo-600;
            }

            &::-webkit-scrollbar-track {
                background-color: $grey-700;
            }
        }

        @media (max-width: $md) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }

    &_support {
        display: flex;
        flex-direction: column;
        align-items: center;

        cursor: none;
        overflow: hidden;
        transition: 100ms 0ms cubic-bezier(0.4, 0, 0.2, 1);

        &__img {
            display: block;
            object-fit: cover;

            height: 100%;
            max-height: 10rem;
            pointer-events: none;
            padding: 0.3rem;
            background-color: white;
        }

        &__info {
            display: flex;
            margin: 0.5rem 0;
            flex-direction: column;
            align-items: center;
            pointer-events: none;
            font-size: 1.3rem;
            font-weight: bold;
            
            @keyframes rainbowLink {
                0% {
                    color: #ff2a2a;
                }
    
                15% {
                    color: #ff7a2a;
                }
    
                30% {
                    color: #ffc52a;
                }
    
                45% {
                    color: #43ff2a;
                }
    
                60% {
                    color: #2a89ff;
                }
    
                75% {
                    color: #202082;
                }
    
                90% {
                    color: #6b2aff;
                }
    
                100% {
                    color: #e82aff;
                }
            }
    
            animation: rainbowLink 1s infinite;
        }

        pointer-events: none;
        // &:hover {
        //     background-color: #6F4E37;
        // }

        @media (max-width: $md) {
            margin-top: 2rem;
            pointer-events: all;
        }
    }
}