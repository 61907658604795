@import "app/styles/font.scss";
@import "app/styles/breakpoints.scss";

.fallback {
    width: 100vw;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    font-family: $pretendard;
    color: var(--text-primary);

    &__code {
        padding: 1rem;
        font-size: 20rem;
        margin-bottom: -3rem;

        @keyframes rainbowLink {
            0% {
                color: #ff2a2a;
            }

            15% {
                color: #ff7a2a;
            }

            30% {
                color: #ffc52a;
            }

            45% {
                color: #43ff2a;
            }

            60% {
                color: #2a89ff;
            }

            75% {
                color: #202082;
            }

            90% {
                color: #6b2aff;
            }

            100% {
                color: #e82aff;
            }
        }

        animation: rainbowLink 1s infinite;

        @media (max-width: $md) {
            font-size: 10rem;
        }
    }

    &__title {
        padding: 1rem;
        font-size: 4rem;
        margin-bottom: 2rem;
        word-break: keep-all;
        text-align: center;
    }

    &__link {
        color: var(--text-primary);

        padding: 1rem 5rem;
        margin: 1rem;
        font-size: 2.5rem;
        background-color: var(--fallback-btn);
        border-radius: 0.6rem;
        word-break: keep-all;
        text-align: center;
        
        &:hover {
            background-color: var(--fallback-btn-hover);
        }

        @media (max-width: $md) {
            font-size: 2rem;
            padding: 1rem 2rem;
        }
    }
}