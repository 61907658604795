@import "app/styles/font.scss";
@import "app/styles/color.scss";
@import "app/styles/breakpoints.scss";

.article {
    color: var(--article-text-primary);

    &__img {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 0.5rem;
        max-height: 13rem;

        @media (max-width: $md) {
            max-height: 12rem;
        }
    }

    &__category {
        display: inline-block;
        margin-top: 1rem;
        color: var(--article-text-category);
        font-size: 1.1rem;
        background: transparent;
        
        cursor: pointer;
    }

    &__title {
        margin-top: 0.5rem;
        font-size: 1.5rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        overflow: hidden;
        // min-height: 3.6rem;
    }

    &__info {
        margin-top: 0.5rem;
        display: flex;
        color: var(--article-text-info);
    }

    &__time::before {
        display: inline-block;
        background-color: var(--article-text-info);
        // border-radius: 50%;
        content: "";
        height: 2px;
        line-height: 1.43;
        margin: 4px 10px;
        width: 6px;
    }
}