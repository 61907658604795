@import "/src/app/styles/font.scss";
@import "/src/app/styles/color.scss";
@import "/src/app/styles/breakpoints.scss";

.md__wrapper {
    height: 100vh;
    width: 100%;

    .w-md-editor {
        height: 100% !important;
    }

    .code-line .token {
        font-size: unset !important;
        line-height: unset !important;
    }

    pre.w-md-editor-text-pre > code,
    .w-md-editor-text-input,
    .w-md-editor-text-input > textarea {
        font-size: 1.3rem !important;
        line-height: 1.618 !important;
        border: 0px !important;
    }

    .w-md-editor-toolbar {
        button {
            height: 3rem;
            width: 3rem;

            @media (max-width: $md) {
                height: 1.5rem;
                width: 1.5rem;
            }
        }

        svg {
            height: 1.5rem;
            width: 1.5rem;

            @media (max-width: $md) {
                height: 1rem;
                width: 1rem;
            }
        }
    }
}
