@import "app/styles/color.scss";
@import "app/styles/themes/dark.scss";

.content {
    color: var(--article-text-primary);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 1.8rem;
        margin-bottom: 1rem;
        font-weight: normal;
    }

    pre > div {
        margin: 2rem 0 !important;

        &::-webkit-scrollbar {
            height: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $indigo-600;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey-700;
        }
    }

    p code {
        display: inline-block;
        background-color: var(--article-code-bg);
        padding: 0.1rem 0.2rem;
    }

    ol,
    ul,
    p,
    blockquote {
        margin-bottom: 1rem;
    }

    blockquote {
        background: var(--article-quote-bg) !important;
        border-radius: 0 !important;
        padding: 1rem 1rem !important;

        border-left: 4px solid var(--article-quote-border);

        & > p {
            margin-bottom: 0;
        }

        & > ul {
            margin-bottom: 0;
        }
    }

    a {
        color: var(--article-link);
        font-weight: 700;
        text-decoration: underline;
        word-break: break-all;
    }

    p {
        word-break: break-all;
    }

    table {
        width: 100%;
        margin-bottom: 2rem;
        border-collapse: collapse;
    }

    th {
        padding: 0.5rem;
        font-weight: bold;
        border-bottom: 1.5px solid #cdcdcd;

        &:not(:last-child) {
            border-right: 0.5px solid #505050;
        }
    }

    tr {
        display: table-row;
    }

    td {
        padding: 0.5rem;
        border-bottom: 1.2px solid #7b7b7b;
        word-break: break-all;

        &:not(:last-child) {
            border-right: 0.5px solid #505050;
        }
    }

    sup > a {
        text-decoration: none;
    }

    .task-list-item {
        list-style: none;
        margin-bottom: 0.5rem;

        & > input[type="checkbox"] {
            zoom: 1.2;
            vertical-align: middle;
        }
    }

    .footnotes {
        margin-top: 2rem;
        border-top: 2px solid #7b7b7b;
        padding-top: 1rem;
    }

    #footnote-label {
        display: none;
    }

    .youtube_wrapper {
        aspect-ratio: 16 / 9;
    }

    iframe {
        display: block;
        width: 100%;
        height: 100%;
    }

    ul {
        list-style: inside;
    }

    > * {
        line-height: 1.75;
    }

    img {
        max-width: 100%;
    }
}
