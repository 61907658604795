@import "/src/app/styles/font.scss";
@import "/src/app/styles/color.scss";
@import "/src/app/styles/breakpoints.scss";

.article_prev_next {
    &__prevnnext_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 2rem;
        margin: 4rem 0;
    }

    &__prev_article,
    &__next_article {
        width: 100%;
        font-size: 1rem;
        font-family: $pretendard;
        font-weight: 500;
        padding: 0.7rem 1rem;

        border: 0.15rem solid var(--profile-edit-btn-bg);
        border-radius: 0.6rem;

        background: var(--article-prevnext-btn-bg);
        color: var(--article-prevnext-text-secondary);

        cursor: pointer;

        &:hover {
            background-color: var(--article-prevnext-btn-hover);
            border: 0.15rem solid var(--article-prevnext-btn-hover);
        }

        display: flex;
        align-items: flex-start;
    }

    &__prev_article::before {
        content: "<";
        font-size: 1.5rem;
        margin-right: 1rem;
    }

    &__next_article::after {
        content: ">";
        font-size: 1.5rem;
        margin-left: 1rem;
    }

    &__prev_wrapper,
    &__next_wrapper {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__prev_title,
    &__next_title {
        font-size: 1.3rem;
        color: var(--article-prevnext-text-primary);
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        margin-top: 0.5rem;
        text-align: left;
    }
}