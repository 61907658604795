@use 'src/app/styles/breakpoints';

.footer {
    position: relative;
    width: 100%;
    min-height: 7rem;
    background: var(--footer-bg);
    color: var(--text-primary);

    &__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1280px;
        padding: 1rem 1.5rem;
    }
    
    &__col {
        display: flex;
        align-items: center
    }

    &__header {
        font-size: 2.5rem;
        font-family: 'Nasalization';
        cursor: pointer;
    }

    &__copyright {
        font-size: 1rem;
    }

    &__list li {
        display: flex;
        align-items: center;
        gap: 7px;

        &:not(:last-child) {
            margin-bottom: 7px;
        }
    }

    &__list li a {
        color: var(--text-footer);
        font-size: 1.3rem;
        
        &:hover {
            color: var(--primary-bg);
        }
    }
}

@media (max-width: breakpoints.$sm) {
    .footer {
        &__wrapper {
            flex-wrap: wrap;
            gap: 25px;
        }
    }
}