@import "/src/app/styles/font.scss";
@import "/src/app/styles/color.scss";
@import "/src/app/styles/breakpoints.scss";

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    font-family: $pretendard;
    flex-grow: 1;
    color: var(--profile-text-primary);

    &__info {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    &__info_wrapper {
        margin: 4rem 0 2rem 0;
        max-width: 40rem;
        min-height: 35rem;
        width: 100%;
        padding: 5.2rem 5rem;

        // border: 0.2rem solid var(--border-accordion);
        border-radius: 5.5rem;
        box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        background-color: var(--profile-bg);

        @media (max-width: $md) {
            grid-template-columns: repeat(2, 1fr);
            padding: 3.2rem 3rem;
            border-radius: 3.5rem;
        }
    }

    &__main_info {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        max-width: 20rem;
        max-height: 20rem;
        border-radius: 100%;

        margin-bottom: 2rem;
    }

    &__image_edit:hover {
        filter: brightness(70%);

        cursor: pointer;
    }


    &__detail_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__email {
        font-size: 1.5rem;
        word-break: break-all;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }

    &__input {
        width: 100%;
        font-size: 1.5rem;
        background: var(--profile-input-bg);
        padding: 1rem;

        text-align: justify;
        font-weight: 500;
        font-family: $pretendard;

        color: var(--text-primary);
        box-shadow: 0 0 0 1px var(--profile-shadow-color),
            0 0 0 var(--profile-shadow-color),
            0 1px 1px var(--profile-shadow-color);

        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }

    &__divider {
        height: 0.2rem;
        width: 100%;
        background: var(--profile-divider);
        margin-top: 2rem;
    }

    &__bio {
        width: 100%;
        min-height: 8rem;
        flex-grow: 1;

        margin-top: 2rem;

        font-size: 1.5rem;
        font-family: $pretendard;

        color: var(--profile-text-primary);
        background: transparent;

        border: none;
        resize: none;
        padding: 0.5rem;


        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $indigo-600;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey-700;
        }

        &:not(:read-only) {
            background: var(--profile-input-bg);
            box-shadow: 0 0 0 1px var(--profile-shadow-color),
                0 0 0 var(--profile-shadow-color),
                0 1px 1px var(--profile-shadow-color);
        }
    }

    &__btn_wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 2rem;
        margin-top: 2rem;
    }

    &__edit_btn {
        flex-grow: 1;
        font-size: 1.2rem;
        font-family: $pretendard;
        font-weight: 500;
        padding: 1rem 2rem;

        border: 0.15rem solid var(--profile-edit-btn-bg);
        border-radius: 0.6rem;

        background: var(--profile-edit-btn-bg);
        color: var(--profile-text-primary);

        cursor: pointer;

        &:hover {
            background-color: var(--profile-edit-btn-hover);
            border: 0.15rem solid var(--profile-edit-btn-hover);
        }
    }

    &__submit_btn {
        flex-grow: 1;
        font-size: 1.2rem;
        font-family: $pretendard;
        font-weight: 500;
        padding: 1rem 2rem;

        border: 0.15rem solid var(--profile-submit-btn-bg);
        border-radius: 0.6rem;

        background: var(--profile-submit-btn-bg);
        color: var(--profile-text-primary);

        cursor: pointer;

        &:hover {
            background-color: var(--profile-submit-btn-hover);
            border: 0.15rem solid var(--profile-submit-btn-hover);
        }
    }

    &__logout_btn {
        flex-grow: 1;
        font-size: 1.2rem;
        font-family: $pretendard;
        font-weight: 500;
        padding: 1rem 2rem;

        border: 0.15rem solid var(--profile-logout-btn-bg);
        border-radius: 0.6rem;

        background: var(--profile-logout-btn-bg);
        color: var(--profile-text-primary);

        cursor: pointer;

        &:hover {
            background-color: var(--profile-logout-btn-hover);
            border: 0.15rem solid var(--profile-logout-btn-hover);
        }
    }
}