@import "/src/app/styles/font.scss";
@import "/src/app/styles/breakpoints.scss";

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    font-family: $pretendard;
    flex-grow: 1;

    .intro {
        width: 100%;
        color: var(--text-primary);
        text-align: center;
        word-break: keep-all;
        margin: 12rem 0 12rem 0;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-content: center;

        @media (max-width: $lg) {
            margin: 4rem 0;
        }

        &__inner-wrapper {
            height: 100%;
            flex-grow: 1;
            display: flex;
            // position: absolute;
            flex-direction: column;
            justify-content: center;
            
            align-items: center;
            gap: 1rem;

            // @media (max-width: $lg) {
            //     align-items: center;
            // }
        }

        &__title,  &__title_md {
            font-size: 4.5rem;
            text-align: left;
            word-break: keep-all;
            text-align: center;
        }

        &__title {
            @media (max-width: $lg) {
                display: none;
            }
        }

        &__title_md {
            display: none;

            @media (max-width: $lg) {
                display: inline;
                
                font-size: 2.5rem;
            }
        }

        &__subtitle {
            font-size: 1.5rem;

            @media (max-width: $lg) {
                font-size: 1rem;
            }
        }

        
        &__terminal {
            max-width: 70rem;
            margin: 6rem 0;
            padding: 1rem;
            background: var(--terminal-bg);
            border-radius: 1rem;

            @media (max-width: $md) {
                display: none;
            }

            @media (max-width: $lg) {
                transform: scale(0.9);
            }
        }
    }

    .main,
    .test {
        &__title {
            color: var(--text-primary);
            font-size: 1.5rem;
            letter-spacing: 0.15rem;

            font-weight: bold;
            line-height: 1.25;

            margin-top: 1rem;
        }

        &__text {
            color: var(--text-secondary);
            font-size: 1rem;
            letter-spacing: 0.15rem;

            font-weight: normal;
            line-height: 1.2;

            display: block;
            margin-bottom: 1.7rem;
        }

        .articles {
            display: grid;
            gap: 64px;
            grid-auto-rows: 1fr;
            padding: 0 0 4rem 0;
            grid-template-columns: repeat(3, 1fr);
    
            @media (max-width: $lg) {
                grid-template-columns: repeat(2, 1fr);
            }
    
            @media (max-width: $md) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}