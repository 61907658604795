.app.dark {
    --white: #262626;
    --black: #fff;
    --light: #161617;
    --dark: #515151;
    --red: #cb0e4e;
    --dark-red: #a00c3d;


    --text-primary: #fff;
    --text-secondary: #cacaca;
    --text-heading: #fff;
    --text-footer: #a1a1a1;
    --text-input: #b2b2b2;


    --border: #515151;
    --border-secondary: #e2e2e2;
    --border-accordion: #e4e4e4;
    --border-check: #bce0fd;
    --border-account: #d9d9d9;


    --login-bg: #1f1f1f;
    --login-btn: #3949ab;
    --login-btn-hover: #3e4fbc;
    --login-input-border: #a2a2a2;
    --login-invalid: #ff8383;
    --login-invalid-bg: #fff3f3;


    --fallback-bg: #1f1f1f;
    --fallback-btn: #3949ab;
    --fallback-btn-hover: #3e4fbc;


    --footer-bg: #000000;
    --header-bg: #000000;


    --header-link: #4aacf6;
    --header-link-active: #536dfe;
    --header-link-hover: #536dfe;
    --header-icon: #fff;


    --article-intro-text-primary: #fff;
    --article-intro-text-secondary: #a1a1a1;
    --article-text-primary: #fff;
    --article-text-secondary: #a1a1a1;
    --article-text-category: #536dfe;
    --article-text-info: #a1a1a1;
    --article-code-bg: #1e1e1e;
    --article-quote-bg: #1e1e1e;
    --article-quote-border: #536dfe;
    --article-link: #536dfe;
    --article-wrapper-bg: #2e2e2e;
    --article-editor-bg: #0d1117;
    --article-editor-bg-active: #2e2e2e;
    --article-shadow-color: #30363d;
    --article-submit-btn: #3949ab;
    --article-submit-btn-hover: #3e4fbc;
    --article-edit-btn: #4caf50;
    --article-edit-btn-hover: #5db160;
    --article-delete-btn: #e53935;
    --article-delete-btn-hover: #e34845;
    --article-divider: #373737;
    --article-prevnext-btn-bg: #536dfe;
    --article-prevnext-btn-hover: #4a65ff;
    --article-prevnext-text-primary: #fff;
    --article-prevnext-text-secondary: #d9d9d9;
    --article-thumbnail-apply-btn: #536dfe;
    --article-thumbnail-apply-btn-hover: #4a65ff;

    --share-btn-bg: #448aff;
    --share-btn-hover: #3982ff;

    --blog-write-btn: #fff;
    --blog-write-btn-bg: #536dfe;
    --blog-write-btn-bg-hover: #4a65ff;
    --blog-pagenation: #fff;
    --blog-pagenation-active: #4a65ff;
    --blog-pagenation-hover: #5770ff;
    --blog-link: #4aacf6;
    --blog-link-active: #536dfe;
    --blog-link-hover: #536dfe;
    --blog-search-bg: #262626;

    --profile-text-primary: #fff;
    --profile-bg: #1f1f1f;
    --profile-input-border: #a2a2a2;
    --profile-divider: #373737;
    --profile-textarea-bg: #454545;
    --profile-edit-btn-bg: #536dfe;
    --profile-edit-btn-hover: #4a65ff;
    --profile-submit-btn-bg: #4caf50;
    --profile-submit-btn-hover: #5db160;
    --profile-logout-btn-bg: #e53935;
    --profile-logout-btn-hover: #e34845;
    --profile-invalid: #ff8383;
    --profile-invalid-bg: #fff3f3;
    --profile-shadow-color: #30363d;
    --profile-input-bg: #0d1117;


    --primary-bg: #2699fb;
    --secondary-bg: #dbd6d6;
    --terminal-bg: #000;
}

body {
    background: #262626;
}