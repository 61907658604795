@import "app/styles/font.scss";
@import "app/styles/color.scss";
@import "app/styles/breakpoints.scss";

.xterm {
    text-align: left;

    &-viewport {
        &::-webkit-scrollbar {
            width: 8px;
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $indigo-600;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey-700;
            display: none;
        }
    }

    &-screen {
        // padding-right: 1rem;
        box-sizing: unset;
    }
}