html {
    font-size: 16px;
    // font-size: clamp(8px, 2.5vw, 16px);
    // font-size: calc(100vw / 200 + 16px);
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    text-rendering: optimizeSpeed;
    overflow-x: hidden;
    // min-width: fit-content;
    // min-height: 100vh;
}

body,
html {
    // height: 100vh;
    width: 100%;
    // overflow-x: hidden;
}

#root {
    min-height: calc(100vh);
}

* {
    margin: 0;
    padding: 0;
    border: none;
    border-spacing: 0;
    outline: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

ol,
ul {
    list-style: none;
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 400;
}

input,
button {
    outline: none;
}

a {
    text-decoration: none;
    color: inherit;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2.3rem;
}

h4 {
    font-size: 1.7rem;
}

h5 {
    font-size: 1.4rem;
}

h6 {
    font-size: 1rem;
}

ol {
    list-style: decimal;
    padding-left: 1.1rem;
}