@import "/src/app/styles/font.scss";
@import "/src/app/styles/color.scss";
@import "/src/app/styles/breakpoints.scss";

.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $pretendard;
    flex-grow: 1;

    .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;

        &__title {
            font-size: 3rem;
            color: var(--article-intro-text-primary);
        }

        &__subtitle {
            font-size: 1.2rem;
            color: var(--article-intro-text-secondary);
        }
    }

    &__navigation-menu-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        margin-top: 4rem;
    }

    &__search-box {
        display: flex;
        border-bottom: 0.15rem var(--text-primary) solid;
    }

    .blog__search-box__wrapper {
        display: flex;
        align-items: center;

        overflow: hidden;
        max-width: 0;
        transition: max-width 0.3s ease;

        &.open {
            max-width: 75vw;
        }
    }

    &__search-type_select {
        background-color: transparent;
        color: var(--text-primary);

        font-family: $pretendard;
        font-size: 1.2rem;

        -o-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-ms-expand {
            display: none;
        }

        option {
            color: var(--text-primary);
            background: var(--blog-search-bg);

            text-align: center;
        }
    }

    &__search-box__line {
        width: 0.1rem;
        height: 1.6rem;
        background: var(--border-secondary);
        margin: 0 0.7rem 0 0.5rem;
    }

    &__search-input {
        background: transparent;
        color: var(--text-primary);
        font-family: $pretendard;
        font-size: 1.2rem;
    }

    &__search-icon {
        fill: var(--text-primary);

        width: 2rem;
        height: 2rem;

        @media (max-width: $md) {
            width: 1.5rem;
            height: 1.5rem;
        }

        cursor: pointer;
    }

    &__navigation-menu-list {
        display: flex;
        padding: 0;
        gap: 1rem;

        @media (max-width: $md) {
            gap: 0.5rem;
        }
    }

    &__navigation-menu-item {
        display: flex;
    }

    &__navigation-menu-link {
        color: var(--text-primary);
        font-size: 1.5rem;

        cursor: pointer;
        text-decoration: none;
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 0% 101%;
        background-repeat: no-repeat;
        background-size: 0% 2px;
        transition: background-size 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);

        &:hover {
            color: var(--blog-link-hover);
            background-size: 100% 2px;
            transition: background-size 0.3s ease-in-out;
        }

        &_active {
            color: var(--blog-link-active) !important;
            background-size: 100% 2px;
        }

        @media (max-width: $md) {
            font-size: 1rem;
        }
    }

    .articles {
        display: grid;
        gap: 64px;
        grid-auto-rows: 1fr;
        padding-top: 2rem;
        padding-bottom: 4rem;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: $lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .write_btn__wrapper {
        width: 100%;
        max-width: 1345px;
        display: flex;
        justify-content: flex-end;

        position: sticky;
        bottom: 1rem;
        padding-right: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: -4rem;
    }

    .write_btn {
        width: 4rem;
        height: 4rem;

        fill: var(--blog-write-btn);
        background: var(--blog-write-btn-bg);

        padding: 0.5rem;
        border-radius: 100%;
        box-shadow: 0px 0px 1rem 0px rgb(28, 28, 28);

        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background: var(--blog-write-btn-bg-hover);
        }

        z-index: 100;
    }

    .pagenation {
        display: flex;
        justify-content: center;
        gap: 1rem;
        color: var(--blog-pagenation);
        font-size: 1.5rem;
        z-index: 101;
        margin-bottom: 2rem;
    }

    .page {
        &.hide {
            visibility: hidden;
        }

        &.active {
            color: var(--blog-pagenation-active);
            font-weight: 500;
        }

        &:hover {
            color: var(--blog-pagenation-hover);
            font-weight: 500;
        }
    }

    .no_article_text {
        font-family: $pretendard;
        font-weight: 500;
        text-align: center;
        color: var(--article-intro-text-primary);
        line-height: 200%;
    }
}
